import { Dropbox } from "dropbox";
import { DROPBOX_REFRESH_TOKEN, DROPBOX_CLIENT_ID, DROPBOX_CLIENT_SECRET } from "../../configs/env";

export default class PdbDropbox {
    private drop: Dropbox;

    constructor() {
        this.drop = new Dropbox({
            refreshToken: DROPBOX_REFRESH_TOKEN,
            clientId: DROPBOX_CLIENT_ID,
            clientSecret: DROPBOX_CLIENT_SECRET            
        });
    }

    public async GetFromServer(path: string): Promise<Blob> {
        const downloadedContent: any = await this.drop.filesDownload({ path: path })

        return downloadedContent.result.fileBlob as Blob;
    }
}